import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'date', headerName: 'Date', width: 150 },
  {
    field: 'distance',
    headerName: 'Distance',
    width: 150,
  },
  {
    field: 'result',
    headerName: 'Result',
    width: 150,
  },
  {
    field: 'shoe',
    headerName: 'Shoe',
    width: 150,
  }
]

export const Table = ({data}) => {
  return (
    <DataGrid
      getRowId={(params) => { return `${params.date}:${params.distance}` }}
      rows={data}
      columns={columns}
      pageSize={7}
      hideFooterPagination
      hideFooter
      rowsPerPageOptions={[7]}
      disableSelectionOnClick
      onRowClick={(_, {id}) => console.log(id)}
    />
  )
}
