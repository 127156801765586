import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { API } from '../api';

const reducers = combineReducers({
  [API.reducerPath]: API.reducer,
})

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
      }}).concat(API.middleware)
  }
})
