import { useGetDetailsQuery } from '../../api';
import { useParams } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';

import { Table } from '../../components/Table';
import { Summary } from '../../components/Summary';
import { Header } from '../header/Header';

export const Details = () => {
  const { id } = useParams()
  const { data,
	  isLoading,
	  isFetching,
	  isError,
	  isUninitialized
	} = useGetDetailsQuery(id);

  if (isLoading || isFetching || isUninitialized || isError) {
    return (<LinearProgress />);
  }

  return (
    <>
      <Header />
      <Divider sx={{mb: '8px'}} />
      <Grid container spacing={1} justifyContent="center">
        <Summary data={data.summary} />
        <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
          <Box sx={{ height: 500, width: '100%' }}>
            <Table data={data.runs} />
          </Box>
        </Grid>      
      </Grid>              
    </>    
  )
}
