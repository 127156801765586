import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';

const columns = [
  { field: 'name', headerName: 'Name', sortable: false, flex: 1, width: '100%'}
]

export const Selection = ({data}) => {
  const apiRef = useGridApiRef()
  const navigate = useNavigate()
  
  return (
    <Grid container rowSpacing={1} sx={{mt: '2px'}}>
      <Grid xs={12} sx={{height: 800}}>
        <DataGrid
          apiRef={apiRef}
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none'
            }
          }}
          getRowId={(params) => { return params.id }}
          rows={data}
          columns={columns}
          pageSize={12}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnFilter
          disableDensitySelector
          disableColumnMenu
          disableColumnSelector
          onRowClick={(params) => navigate(`/${params.row.name}/${params.row.id}`)}
        />          
      </Grid>
    </Grid>
  )
}

