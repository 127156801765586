import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

export const ScoreCard = ({title, value}) => {
  const theme = useTheme()
  return (
    <Card sx={{display: 'flex', backgroundColor: theme.palette.primary.main, }}>
      <CardContent>
        <Typography sx={{textAlign: 'center', fontWeight: 'bold'}} color={theme.palette.info.main} gutterBottom>
          { title }
        </Typography>
        <Typography key={value} sx={{ textAlign: 'center', fontWeight: 'bold' }} color={theme.palette.warning.main}>
          { value }
        </Typography>              
      </CardContent>
    </Card>    
  )
}

