import { useGetNamesQuery } from '../../api';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import { Selection } from '../../components/Selection';
import Grid from '@mui/material/Unstable_Grid2';

import { Header } from '../header/Header';

export const List = () => {
  const { data,
	  isLoading,
	  isFetching,
	  isError,
	  isUninitialized
	} = useGetNamesQuery();

  if (isLoading || isFetching || isUninitialized || isError) {
    return (<LinearProgress />)
  }

  return (
    <>
      <Header />
      <Selection data={data} />
    </>    
  )
}
