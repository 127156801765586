import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const API = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL
  }),
  endpoints: (builder) => ({
    getNames: builder.query({
      query: () => '/'
    }),
    getDetails: builder.query({
      query: (id) => `/${id}`
    })
  })
})

export const { useGetNamesQuery, useGetDetailsQuery } = API
