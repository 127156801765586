import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

export const Header = () => {
  const theme = useTheme()
  const { name } = useParams();
  const navigate = useNavigate()
  return (
    <>
      <AppBar position="static">      
        <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <IconButton onClick={() => navigate('/')}>
            <HomeIcon sx={{color: theme.palette.common.white}}/>
          </IconButton>
          {
            name && <Typography sx={{fontWeight: 'bold'}}> { name } </Typography>
          }          
          {
            name && <Avatar alt={name} src={`https://ui-avatars.com/api/?name=${name}`} />            
          }          
        </Toolbar>
      </AppBar>
    </>
  )
}

// https://ui-avatars.com/api/?name=russian+romance
