import {
  Route,
  Routes
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { List } from './features/list/List';
import { Details } from './features/details/Details';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#52524E',
    },
    secondary: {
      main: '#f50057',
    },
  }  
});

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={darkTheme}>        
        <Routes>
          <Route exact path="/" element={<List />} />
          <Route path="/:name/:id" element={<Details />} />
        </Routes>
      </ThemeProvider>
    </>   
  );
}

export default App;
