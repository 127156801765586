import { ScoreCard } from './ScoreCard';
import Grid from '@mui/material/Unstable_Grid2';

export const Summary = ({data}) => {
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid display="flex" justifyContent="center" alignItems="center">
        <ScoreCard title="Total Runs" value={data.totalRuns} />
      </Grid>
      <Grid display="flex" justifyContent="center" alignItems="center">
        <ScoreCard title="First" value={data.first} />
      </Grid>
      <Grid display="flex" justifyContent="center" alignItems="center">
        <ScoreCard title="Second" value={data.second} />
      </Grid>
      <Grid display="flex" justifyContent="center" alignItems="center">
        <ScoreCard title="Third" value={data.third} />
      </Grid>
      <Grid display="flex" justifyContent="center" alignItems="center">
        <ScoreCard title="Fourth" value={data.fourth} />
      </Grid>
      <Grid display="flex" justifyContent="center" alignItems="center">
        <ScoreCard title="Unplaced" value={data.unplaced} />
      </Grid>      
      <Grid display="flex" justifyContent="center" alignItems="center">
        <ScoreCard title="Net Stake" value={new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.stake)} />
      </Grid>      
    </Grid>
  )
}
